import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useEffect, useState } from 'react';
import { getIdentifier } from '@/lib/organization-utils';
var defaultConfigs = {
  redirectUrl: 'https://about.motimateapp.com/',
  ctaButton: null,
  image: null,
  sidebarImage: null,
  gaCode: null
};
export default function useOrgConfigs() {
  var _getIdentifier;

  var _useState = useState(defaultConfigs),
      configs = _useState[0],
      setConfigs = _useState[1];

  var identifier = false ? '' : (_getIdentifier = getIdentifier()) === null || _getIdentifier === void 0 ? void 0 : _getIdentifier.replace('-open', '');
  useEffect(function () {
    if (identifier === 'dnt') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.dnt.no/digitale-friluftskurs-/',
      image: 'https://res.cloudinary.com/motimate-staging/image/upload/c_limit,d_ohno_ixrvnq.jpg,h_1080,q_auto,w_1000/v1/motimate/staging/motimate/epgvuhf9q1vjes4x1bdf.jpg'
    }));
    if (identifier === 'dnb') setConfigs(defaultConfigs);
    if (identifier === 'nkk') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.nkk.no/digitale-kurs/category1402.html',
      image: 'https://open-courses.s3-eu-west-1.amazonaws.com/nkk-open/images/logo.png',
      sidebarImage: 'https://open-courses.s3-eu-west-1.amazonaws.com/nkk-open/images/logo2.png'
    }));
    if (identifier === 'norsis') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://norsis.no/kurs/'
    }));
    if (identifier === 'security') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.motimateapp.com/norsis-cyber-security-courses/?utm_source=motimateapp.com&utm_medium=open-moti&utm_campaign=norsis-intl'
    }));
    if (identifier === 'volvat') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.volvat.no/'
    }));
    if (identifier === 'equality-check') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.motimateapp.com/courses/equality-check-campaign/'
    }));
    if (identifier === 'suldal-vekst') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      redirectUrl: 'https://www.svr.no/'
    })); // internal

    if (identifier === 'hello') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      ctaButton: {
        action: 'Book a demo',
        redirectUrl: 'https://www.motimateapp.com/',
        lastChapterAction: 'Exit'
      }
    })); // internal

    if (identifier === 'events') setConfigs(_objectSpread(_objectSpread({}, defaultConfigs), {}, {
      ctaButton: {
        action: 'Book Demo',
        lastChapterAction: 'Book Demo',
        redirectUrl: 'https://www.motimateapp.com/book-demo/?utm_source=events-open.motimate.app&utm_medium=open_moti&utm_campaign=workmeetup2022'
      },
      redirectUrl: 'https://www.motimateapp.com/book-demo/?utm_source=events-open.motimate.app&utm_medium=open_moti&utm_campaign=workmeetup2022'
    }));
  }, [identifier]);
  return configs;
}